import React, { useContext, useEffect } from "react"
import { ChevronRight, User } from "react-feather"
import HelmetHead from "../../components/HelmetHead"
import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
import { Link } from "gatsby"
import BackgroundSection from "../../components/BackgroundSection"
const CTA2 = Loadable(() => import("../../components/CTA2"))

import Loadable from "@loadable/component"
import Image from "../../components/Image"
import LoadGatsbyOptimizedImage from "../../components/LoadGatsbyOptimizedImage"
import LayoutContext from "../../components/LayoutContext"

const IndexPage = ({ location }) => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {},
    }))
  }, [])

  const images = LoadGatsbyOptimizedImage()

  const StartseiteBilderKasten1 = images["custom"].edges.find(
    ({ node }) =>
      node.childImageSharp &&
      node.childImageSharp.gatsbyImageData.images.fallback.src
        .split("/")
        .pop() === "StartseiteBilderKasten1.jpg"
  ).node.childImageSharp.gatsbyImageData.images.fallback.src

  const StartseiteBilderKasten2 = images["custom"].edges.find(
    ({ node }) =>
      node.childImageSharp &&
      node.childImageSharp.gatsbyImageData.images.fallback.src
        .split("/")
        .pop() === "Maklerempfehlung.png"
  ).node.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <>
      <HelmetHead
        title="Erstklassige Maklerempfehlung und Immobilienbewertung"
        description="Maklerzentrale. Erste Anlaufstelle für den Immobilienverkauf. Kostenlose Immobilienbewertung vom Fachmann. Erstklassige Maklerempfehlung."
        keywords="Maklerzentrale, Immobilienmakler"
        img={`${process.env.GATSBY_SITE_URL}${StartseiteBilderKasten2}`}
      />
      <BackgroundSection src="Immobilie-bewerten-Maklerzentrale.jpg" className="bg-cover">
        <div className="container">
          <div
            className="row justify-content-center text-center align-items-end pb-10"
            style={{ minHeight: "90vh" }}
          >
            <div className="col-12">
              <h1 className="display-3 mb-5 fw-bold text-white pt-15 text-shadow-custom">
                Maklerzentrale
              </h1>
              <h2 className="display-4 text-white text-center fw-bold text-shadow-custom">
                Erste Anlaufstelle für den Immobilienverkauf
              </h2>
              <div className="pt-5">
                <Link
                  to="/de/maklerempfehlung/"
                  className="btn btn-primary shadow lift mb-4 mx-2"
                  style={{ width: "200px" }}
                >
                  Maklerempfehlung
                </Link>
                <Link
                  to="/de/immobilienbewertung/"
                  className="btn btn-primary-desat-soft lift mb-4 mx-2"
                  style={{ width: "200px", backgroundColor: "#e0f2fa" }}
                >
                  Immobilienbewertung
                </Link>
              </div>
            </div>
          </div>
        </div>
      </BackgroundSection>
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>
      {/*<section className="py-7 py-md-9 bg-white">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row justify-content-center mb-5">*/}
      {/*      <div className="col-12 col-md-10 col-lg-8 text-center">*/}
      {/*        /!*<h3 className="fw-bold">Ihre Vorteile</h3>*!/*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row justify-content-center">*/}
      {/*      <div className="col-12 col-md-6 col-lg-4">*/}
      {/*        <div*/}
      {/*          className="icon mb-4 text-center text-primary-desat"*/}
      {/*          data-sal="slide-down"*/}
      {/*          data-sal-delay="200"*/}
      {/*          data-sal-duration="700"*/}
      {/*        >*/}
      {/*          <Home />*/}
      {/*        </div>*/}
      {/*        <h4 className="fw-bold text-center">*/}
      {/*          Experten für den*/}
      {/*          <br />*/}
      {/*          Immobilienverkauf*/}
      {/*        </h4>*/}
      {/*      </div>*/}
      {/*      <div className="col-12 col-md-6 col-lg-4 order-md-2 order-lg-1">*/}
      {/*        <div*/}
      {/*          className="icon mb-4 text-center text-primary-desat"*/}
      {/*          data-sal="slide-down"*/}
      {/*          data-sal-delay="200"*/}
      {/*          data-sal-duration="700"*/}
      {/*        >*/}
      {/*          <ClipboardCheck />*/}
      {/*        </div>*/}
      {/*        <h4 className="fw-bold text-center">*/}
      {/*          Immobilienbewertung*/}
      {/*          <br />*/}
      {/*          vom Fachmann*/}
      {/*        </h4>*/}
      {/*      </div>*/}
      {/*      <div className="col-12 col-md-6 col-lg-4 order-md-1 order-lg-2">*/}
      {/*        <div*/}
      {/*          className="icon mb-4 text-center text-primary-desat"*/}
      {/*          data-sal="slide-down"*/}
      {/*          data-sal-delay="200"*/}
      {/*          data-sal-duration="700"*/}
      {/*        >*/}
      {/*          <UserSvg />*/}
      {/*        </div>*/}
      {/*        <h4 className="fw-bold text-center">*/}
      {/*          Erstklassige*/}
      {/*          <br />*/}
      {/*          Maklerempfehlung*/}
      {/*        </h4>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<section>*/}
      {/*  <div className="container-fluid">*/}
      {/*    <div className="row justify-content-center bg-primary">*/}
      {/*      <div className="d-flex justify-content-around py-8">*/}
      {/*        <div className="d-flex px-8">*/}
      {/*          <div className="badge badge-lg badge-rounded-circle bg-success-soft me-2">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-success fs-lg mb-0">100% kostenlos</p>*/}
      {/*        </div>*/}
      {/*        <div className="d-flex px-8">*/}
      {/*          <div className="badge badge-lg badge-rounded-circle bg-success-soft me-2">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-success fs-lg mb-0">*/}
      {/*            100% unverbindlich*/}
      {/*          </p>*/}
      {/*        </div>*/}
      {/*        <div className="d-flex px-8">*/}
      {/*          <div className="badge badge-lg badge-rounded-circle bg-success-soft me-2">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-success fs-lg mb-0">100% sicher</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className="my-7">
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-md-7 mb-5 mb-md-0 position-relative">
              <div className="mb-5">
                <h1 className="display-4 text-primary">
                  Immobilienbewertung
                  <br />
                  vom Fachmann
                </h1>
              </div>

              <div className="w-lg-85">
                <h2 className="">Was ist Ihre Immobilie wert?</h2>

                <div className="row">
                  <div className="col-sm-auto">
                    <Link
                      to="/de/immobilienbewertung/"
                      className="btn btn-primary-desat-soft"
                    >
                      Zur kostenlosen Bewertung
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-5 banner-half-end"
              style={{ position: "inherit" }}
            >
              <div
                className="h-100 shadow-lg"
                style={{
                  backgroundImage: `url(${StartseiteBilderKasten1})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50%",
                  backgroundSize: "cover",
                  animation: "morph 8s ease-in-out 1s infinite",
                  backgroundBlendMode: "multiply",
                  boxShadow: "inset 0 0 0 4px hsla(0,0%,100%,.3)",
                  borderRadius: "5px",
                  overflow: "hidden",
                  minHeight: "400px",
                }}
              >
                {/*<div className="w-75 mx-auto ms-sm-auto">*/}
                {/*  <img*/}
                {/*    className="img-fluid"*/}
                {/*    src="http://localhost:9000/static/80ebd75177aeab661dace237849d4867/37f94/haus_dunkler3.webp"*/}
                {/*    alt="Image Description"*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-7">
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-md-7 mb-5 mb-md-0 position-relative order-md-2">
              <div className="mb-5">
                <h1 className="display-4 text-primary">
                  Erstklassige Maklerempfehlung
                </h1>
              </div>

              <div className="w-lg-85">
                <h2 className="">Finden Sie den passenden Makler</h2>

                <div className="row">
                  <div className="col-sm-auto">
                    <Link
                      to="/de/maklerempfehlung/"
                      className="btn btn-primary-desat-soft"
                    >
                      Zur unverbindlichen Maklerempfehlung
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-5 banner-half-start order-md-1"
              style={{ position: "inherit" }}
            >
              <div
                className="h-100 shadow-lg"
                style={{
                  backgroundImage: `url(${StartseiteBilderKasten2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50%",
                  backgroundSize: "cover",
                  animation: "morph 8s ease-in-out 1s infinite",
                  backgroundBlendMode: "multiply",
                  boxShadow: "inset 0 0 0 4px hsla(0,0%,100%,.3)",
                  borderRadius: "5px",
                  overflow: "hidden",
                  minHeight: "400px",
                }}
              >
                {/*<div className="w-75 mx-auto ms-sm-auto">*/}
                {/*  <img*/}
                {/*    className="img-fluid"*/}
                {/*    src="http://localhost:9000/static/80ebd75177aeab661dace237849d4867/37f94/haus_dunkler3.webp"*/}
                {/*    alt="Image Description"*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<section className="pt-8 pt-md-11 pb-8">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-12">*/}
      {/*        <div className="card-group card-border card-border-lg border-primary shadow-light-lg">*/}
      {/*          <div className="card">*/}
      {/*            <div className="card-body">*/}
      {/*              <h2 className="fw-bold text-center mb-0">+ 3.000</h2>*/}
      {/*              <p className="text-center text-muted mb-0">Einwertungen</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="card border-start-md">*/}
      {/*            <div className="card-body">*/}
      {/*              <h2 className="fw-bold text-center mb-0">*/}
      {/*                ~ 60 Millionen €*/}
      {/*              </h2>*/}
      {/*              <p className="text-center text-muted mb-0">*/}
      {/*                Immobilienwert*/}
      {/*              </p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="card border-start-md">*/}
      {/*            <div className="card-body">*/}
      {/*              <h2 className="fw-bold text-center mb-0">+ 20.000</h2>*/}
      {/*              <p className="text-center text-muted mb-0">Makler *</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section className="py-8 py-md-11 bg-gray-200 border-bottom border-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6" style={{ zIndex: "1" }}>
              <div className="card card-border shadow-lg mb-5 text-secondary">
                <div className="card-body">
                  <h2 className="">
                    Preisatlas für Immobilien
                    <br />
                    in Deutschland
                  </h2>
                  <h3 className="fw-bold">
                    Kostenlos ohne Anmeldung oder Inserat
                  </h3>
                  <p className="text-muted">
                    Im Durchschnitt liegen die Quadratmeterpreise für Immobilien
                    in Deutschland zwischen 810 € und 9.136 € pro Quadratmeter.
                  </p>

                  <Link
                    to="/de/immobilienpreise-deutschland/"
                    className="fs-sm fw-bold text-decoration-none"
                  >
                    Übersicht über durchschnittliche Quadratmeterpreise in Ihrer
                    Region <ChevronRight size={17} className="align-middle" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-7 mb-lg-0 mt-n15 mt-md-0">
              <Image
                src="Preisatlas.png"
                // loadFrom="logo"
                style={{
                  height: "auto",
                  transform: "scale(1.2)",
                }}
                className="img-fluid"
                alt="Maklerzentrale"
              />
            </div>
          </div>

          <div className="row align-items-center mt-8">
            <div className="col-md-3 px-sm-8 px-md-5 px-lg-8">
              <div className="row">
                <div className="col-6 col-md-12">
                  <a href="https://g.page/Maklerzentrale?share" target="_blank">
                    <Image
                      src="google-bewertungen.png"
                      alt="Google Bewertung"
                      className=""
                      style={{ height: "auto" }}
                    />
                  </a>
                </div>
                <div className="col-6 col-md-12 px-5">
                  <a
                    href="https://de.trustpilot.com/review/maklerzentrale.com"
                    target="_blank"
                  >
                    <Image
                      src="trustpilot.png"
                      alt="Trustpilot Bewertung"
                      className="mb-2"
                      style={{ height: "auto" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <blockquote className="blockquote text-center">
                <p className="mb-5">
                  "Ich möchte mich herzlich bei Ihnen für die Wertermittlung
                  bedanken! Ihre Tipps haben uns sehr weitergeholfen und ich
                  habe mich bei Ihnen von Anfang an sehr gut aufgehoben
                  gefühlt.”
                </p>
                <footer className="blockquote-footer">
                  <User size={15} className="me-2" />
                  <span className="h6">
                    S.B. -{" "}
                    <Link to="/de/bewertungen/" target="_blank">
                      Kundenbewertung
                    </Link>{" "}
                    vom 22.04.2021
                  </span>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10">
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div
              className={`col-md-6 mb-5 mb-md-0 ` /*banner-half-start*/}
              style={{ position: "inherit" }}
            >
              <Image
                src="Maklerverzeichnis.png"
                style={{ height: "auto", width: "100%" }}
                className="img-fluid my-4 mx-auto"
                alt="Maklerzentrale Maklerverzeichnis Eintrag"
              />
            </div>
            <div className="col-md-6 mb-5 mb-md-0 position-relative">
              <div className="mb-5">
                <h1 className="display-4 text-primary">Maklerverzeichnis</h1>
              </div>

              <div className="w-lg-85">
                <h2 className="">Mit über 20.000 Makler</h2>
                {/*<p>*/}
                {/*  Erfahren Sie mehr über Ihren Immomakler*/}
                {/*  <br />- Zahlen, Daten, Fakten*/}
                {/*</p>*/}

                <div className="row">
                  <div className="col-sm-auto">
                    <a
                      href="https://verzeichnis.maklerzentrale.com/makler-finden/"
                      className="btn btn-primary-desat-soft"
                    >
                      Vergleichen Sie Makler in Ihrer Nähe
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="row mt-4">*/}
          {/*  {[1, 2, 3].map(i => (*/}
          {/*    <div*/}
          {/*      className={*/}
          {/*        `col-12 col-sm-6 col-md-4 ` +*/}
          {/*        (i === 2 ? "d-none d-md-block" : "")*/}
          {/*      }*/}
          {/*    >*/}
          {/*      <div className="card shadow-light-lg">*/}
          {/*        <a*/}
          {/*          href="https://verzeichnis.maklerzentrale.com/makler/wohngold-immobilien/"*/}
          {/*          target="_blank"*/}
          {/*          className="card-body my-auto mx-auto"*/}
          {/*        >*/}
          {/*          <div className="avatar-group justify-content-center d-flex">*/}
          {/*            <div className="avatar avatar-xxl">*/}
          {/*              <img*/}
          {/*                src="https://wohngold-immobilien.de/wp-content/uploads/simon-kuhlmann-laptop-960x641.jpg"*/}
          {/*                alt="Simon Kuhlmann"*/}
          {/*                className="avatar-img rounded-circle"*/}
          {/*              />*/}
          {/*            </div>*/}
          {/*            <div className="avatar avatar-xxl">*/}
          {/*              <img*/}
          {/*                src="https://wohngold-immobilien.de/wp-content/uploads/wohngold-marcel-amberge-960x641.jpg"*/}
          {/*                alt="Marcel Amberge"*/}
          {/*                className="avatar-img rounded-circle"*/}
          {/*              />*/}
          {/*            </div>*/}
          {/*          </div>*/}

          {/*          /!*<div className="avatar avatar-xxl d-block mx-auto">*!/*/}
          {/*          /!*  <img*!/*/}
          {/*          /!*    src="https://wohngold-immobilien.de/wp-content/uploads/simon-kuhlmann-laptop-960x641.jpg"*!/*/}
          {/*          /!*    alt="Simon Kuhlmann"*!/*/}
          {/*          /!*    className="avatar-img rounded-circle"*!/*/}
          {/*          /!*  />*!/*/}
          {/*          /!*</div>*!/*/}

          {/*          <h3 className="my-3 text-center">*/}
          {/*            Simon Kuhlmann*/}
          {/*            <br />& Marcel Amberge*/}
          {/*          </h3>*/}

          {/*          <img*/}
          {/*            src="https://wohngold-immobilien.de/wp-content/themes/remjnd/assets/wohngold-immobilen-logo.svg"*/}
          {/*            alt="Wohngold Immobilien"*/}
          {/*            className="mx-5"*/}
          {/*          />*/}

          {/*          <hr className="card-meta-divider my-4" />*/}

          {/*          <h6 className="text-muted text-center mb-0">*/}
          {/*            Makler in Köln*/}
          {/*          </h6>*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  ))}*/}
          {/*</div>*/}
        </div>
      </section>

      <CTA2
        src="deutsche-kinder-krebshilfe.jpg"
        style={{ backgroundPosition: "center 30%" }}
        overlayColor="linear-gradient(rgba(0,0,0,.05), rgba(0,0,0,.05))"
      >
        <div className="row pt-lg-10">
          <div className="col">
            <p className="h2 text-white text-shadow-custom">
              Helfen ist Herzenssache
            </p>
            <p className="text-white fs-lg text-shadow-custom mb-0">
              Unser Anliegen ist, anderen zu helfen. Wir unterstützen
              krebskranke Kinder.
            </p>
          </div>
          <div className="col-auto d-flex align-items-center">
            <a href="https://www.kinderkrebsstiftung.de/" target="_blank">
              <Image
                src="dkks.png"
                // loadFrom="logo"
                style={{ height: "auto", width: "130px" }}
                className="mb-0 mb-sm-2"
                alt="Deutsche Kinderkrebsstiftung"
              />
            </a>
          </div>
        </div>
      </CTA2>
    </>
  )
}

export default IndexPage
